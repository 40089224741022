.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .pagination span {
    font-weight: bold;
  }